import { Sorting } from "./../paginateList";
export class UserListFilter {
    cf: string;
    cognome: string;
    nome: string;
    ente: string | null;
    ruolo: string | null;

    constructor() {
        this.cf = "";
        this.cognome = "";
        this.nome = "";
        this.ente = null;
        this.ruolo = null;
    }
}

export enum LabelMap {
    cf = "cf",
    cognome = "surname",
    nome = "name",
    ente = "institution",
    ruolo = "role",
}

export const sortList: Sorting[] = [
    { campo: "nome", verso: "ASC" },
    { campo: "nome", verso: "DESC" },
    { campo: "cognome", verso: "ASC" },
    { campo: "cognome", verso: "DESC" },
    { campo: "cf", verso: "ASC" },
    { campo: "cf", verso: "DESC" },
];
