import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, of } from "rxjs";
import { User } from "@app/models/user";
import { environment } from "src/environments/environment";
import { PaginateList } from "@app/models/paginateList";
import { PaginateFilter } from "@app/models/filters/paginateFilter";
import { UserListFilter } from "@app/models/filters/userListFilter";
import { ResultList } from "@app/models/resultList";
import { RoleInstitution } from "@app/models/role";
import { AutocompleteItem } from "design-angular-kit";

const baseUrl = environment.servicesUsersUrl;

@Injectable({
    providedIn: "root",
})
export class UserService {
    private userListSubject = new BehaviorSubject<PaginateList<User>>(new PaginateList());
    userList = this.userListSubject.asObservable();
    private userListFilterSubject = new BehaviorSubject<UserListFilter>(new UserListFilter());
    userListFilter = this.userListFilterSubject.asObservable();

    constructor(private http: HttpClient) {}

    setUserList(userList: PaginateList<User>): void {
        this.userListSubject.next(userList);
    }

    getUserList(): PaginateList<User> {
        return this.userListSubject.getValue();
    }

    setUserListFilters(userFilterList: UserListFilter): void {
        this.userListFilterSubject.next(userFilterList);
    }

    getUserListFilters(): UserListFilter {
        return this.userListFilterSubject.getValue();
    }

    getAll(filters: PaginateFilter<UserListFilter>): Observable<PaginateList<User>> {
        return this.http.post<PaginateList<User>>(`${baseUrl}/utenti`, filters);
    }

    get(id: string | number): Observable<User> {
        return this.http.get<User>(`${baseUrl}/utente/${id}`);
    }

    getUserRole(id: number): Observable<RoleInstitution> {
        return this.http.get<RoleInstitution>(`${baseUrl}/utente/enteruolo/${id}`);
    }

    getUserRoles(id: string): Observable<ResultList<RoleInstitution>> {
        return this.http.get<ResultList<RoleInstitution>>(`${baseUrl}/utente/${id}/entiruoli`);
    }

    getProfile(): Observable<User> {
        return this.http.get<User>(`${baseUrl}/utente/profilo`);
    }

    getProfileRoles(): Observable<ResultList<RoleInstitution>> {
        return this.http.get<ResultList<RoleInstitution>>(`${baseUrl}/utente/profilo/entiruoli`);
    }

    search = (search?: string | null): Observable<Array<AutocompleteItem>> => {
        if (search) {
            const params = new HttpParams().set("testo", search);
            return this.http.get<ResultList<User>>(`${baseUrl}/utenti/bytesto`, { params }).pipe(
                map((res) =>
                    res.risultati.map((x: User) => ({
                        value: `${x.cf} - ${x.nome} ${x.cognome}`,
                        additionalData: x,
                    }))
                )
            );
        } else return of([]);
    };

    searchCF = (search?: string | null): Observable<Array<AutocompleteItem>> => {
        if (search) {
            const params = new HttpParams().set("testo", search);
            return this.http.get<ResultList<User>>(`${baseUrl}/utenti/bytesto`, { params }).pipe(
                map((res) =>
                    res.risultati.map((x: User) => ({
                        value: x.cf,
                        label: x.nome && x.cognome ? `${x.nome} ${x.cognome}` : "",
                        additionalData: x,
                    }))
                )
            );
        } else return of([]);
    };

    create(data: User): Observable<any> {
        return this.http.post(`${baseUrl}/utente`, data);
    }

    createUserRole(id: string, data: RoleInstitution): Observable<any> {
        return this.http.post(`${baseUrl}/utente/${id}/enteruolo`, data);
    }

    updateProfile(data: User): Observable<any> {
        return this.http.put(`${baseUrl}/utente/profilo`, data);
    }

    updateUserRole(data: RoleInstitution): Observable<any> {
        return this.http.put(`${baseUrl}/utente/enteruolo/${data.uid}`, data);
    }

    updateProfileContact(data: RoleInstitution): Observable<any> {
        return this.http.put(`${baseUrl}/utente/profilo/enteruolo/${data.uid}/contatti`, data);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${baseUrl}/utente/${id}`);
    }

    deleteUserRole(id: number): Observable<any> {
        return this.http.delete(`${baseUrl}/utente/enteruolo/${id}`);
    }
}
